body {
  margin: 0;
  font-family: Roboto;
  background: #F8F8F8;
}

a {
  color: inherit;
  text-decoration: none;
}

.news-item {

}

.news-item img {
  max-width: 100%;
}

.news-item figure {
  margin: 0;
}

.news-item a {
  font-weight: 400;
  text-decoration: underline;
}

.micondo-page {
  margin: 35px;
}
