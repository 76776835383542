.property-balance {
  background: #f6ca38;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  right: 2rem;
  padding: 0.5rem 1rem;
  transform: translateY(-50%);
  color: white;
  display: flex;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 1.5rem;
}

.property-balance:hover {
  background: #f6ca38;
}

.property-balance-label {
  position: absolute;
  top: 105%;
  left: 50%;
  font-weight: 100;
  font-size: 0.8rem;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: white;
  opacity: 0.7;
}

.property-balance-amount {
  color: white;
  font-weight: 400;
  margin-left: 1rem;
  white-space: nowrap;
}
