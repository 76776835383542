@media only screen and (max-width: 480px) {
  .micondo-appbar .micondo-condomenu {
    display: none;
  }

  .micondo-page {
    margin: 0.5rem;
  }

  .micondo-page.container-fluid {
    padding: 0;
  }
}
