.micondo-panel {
  position: relative;
  min-width: 320px;
}

.micondo-panel form.editor {
  margin-top: 1.5rem;
  box-shadow: inset 0px 0px 9px rgba(0, 0, 0, .2);
  padding: 1.5rem;
  border-radius: 4px;
  background: #f8f8f8;
}

.micondo-panel-disabled {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  opacity: .2;
  transform: translate3d(-50%, -50%, 0);
  background: #FFFFFF;
  transition: 200ms all ease-in-out;
}

.micondo-panel-disabled.show {
  width: 100%;
  height: 100%;
  opacity: .8;
  z-index: 10;
}
